// src/utils/calculations.js
export const calculatePoints = (homeScore, awayScore) => {
    if (homeScore > awayScore) {
      return [3, 0];
    } else if (homeScore < awayScore) {
      return [0, 3];
    } else {
      return [1, 1];
    }
  };
  
  export const calculateGoalDifference = (homeScore, awayScore) => [
    homeScore - awayScore,
    awayScore - homeScore,
  ];