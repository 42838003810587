// src/components/FixturesList.js
import React, { useState, useEffect } from 'react';
import '../styles/FixturesList.css';

const FixturesList = ({ fixtures, standings, onScoreUpdate, scores: initialScores, onAutoComplete }) => {
  const [scores, setScores] = useState(initialScores);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setScores(initialScores);
  }, [initialScores]);

  const handleScoreChange = (fixtureId, team, score) => {
    const updatedScores = {
      ...scores,
      [`${fixtureId}_${team}`]: score,
    };
    setScores(updatedScores);
    onScoreUpdate(updatedScores);
  };

  const handleAutoPopulate = (scorePattern) => {
    const autoPopulatedScores = { ...scores };
    fixtures.forEach((fixture) => {
      if (
        scores[`${fixture.id}_home`] === undefined &&
        scores[`${fixture.id}_away`] === undefined &&
        fixture.home_score === null &&
        fixture.away_score === null
      ) {
        autoPopulatedScores[`${fixture.id}_home`] = scorePattern[0];
        autoPopulatedScores[`${fixture.id}_away`] = scorePattern[1];
      }
    });
    setScores(autoPopulatedScores);
    onScoreUpdate(autoPopulatedScores);
    onAutoComplete();
  };
  
  const handleFavourites = () => {
    const favouritesScores = { ...scores };
    fixtures.forEach((fixture) => {
      if (
        scores[`${fixture.id}_home`] === undefined &&
        scores[`${fixture.id}_away`] === undefined &&
        fixture.home_score === null &&
        fixture.away_score === null
      ) {
        const homeTeam = standings.find((team) => team.team_id === fixture.home_id);
        const awayTeam = standings.find((team) => team.team_id === fixture.away_id);
        if (homeTeam.points > awayTeam.points) {
          favouritesScores[`${fixture.id}_home`] = '2';
          favouritesScores[`${fixture.id}_away`] = '1';
        } else {
          favouritesScores[`${fixture.id}_home`] = '1';
          favouritesScores[`${fixture.id}_away`] = '2';
        }
      }
    });
    setScores(favouritesScores);
    onScoreUpdate(favouritesScores);
    onAutoComplete();
  };

  const handleReset = () => {
    const resetScores = {};
    fixtures.forEach((fixture) => {
      resetScores[`${fixture.id}_home`] = undefined;
      resetScores[`${fixture.id}_away`] = undefined;
    });
    setScores(resetScores);
    onScoreUpdate(resetScores);
  };

  return (
    <div className="fixtures-list">
      <div className="fixtures-header">
        <h2>Fixtures</h2>
        <div className="auto-populate-buttons">
          <button
            className="auto-populate-button"
            onClick={() => handleAutoPopulate(['2', '1'])}
            title="Fill all fixtures with 2-1"
          >
            2-1
          </button>
          <button
            className="auto-populate-button"
            onClick={() => handleAutoPopulate(['1', '1'])}
            title="Fill all fixtures with 1-1"
          >
            1-1
          </button>
          <button
            className="auto-populate-button"
            onClick={() => handleAutoPopulate(['1', '2'])}
            title="Fill all fixtures with 1-2"
          >
            1-2
          </button>
          <button
            className="favourites-button"
            onClick={handleFavourites}
            title="Fill scores based on current standings"
          >
            ★
          </button>
          <button
            className="reset-button"
            onClick={handleReset}
            title="Reset all scores"
          >
            ×
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Home Team</th>
            <th className='score-input'>Score</th>
            <th>Away Team</th>
          </tr>
        </thead>
        <tbody>
          {fixtures.map((fixture) => (
            <tr key={fixture.id}>
                          <td>
              {isMobile
                ? new Date(fixture.date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })
                : new Date(fixture.date).toLocaleDateString()}
            </td>
              <td>
                <img src={fixture.home_logo} alt={fixture.home_name} className="team-logo" />
                <span className="team-name">{fixture.home_name}</span>
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  value={scores[`${fixture.id}_home`] !== undefined ? scores[`${fixture.id}_home`] : fixture.home_score !== null ? fixture.home_score.toString() : ''}
                  onChange={(e) => handleScoreChange(fixture.id, 'home', e.target.value)}
                />
                -
                <input
                  type="number"
                  min="0"
                  value={scores[`${fixture.id}_away`] !== undefined ? scores[`${fixture.id}_away`] : fixture.away_score !== null ? fixture.away_score.toString() : ''}
                  onChange={(e) => handleScoreChange(fixture.id, 'away', e.target.value)}
                />
              </td>
              <td>
                <img src={fixture.away_logo} alt={fixture.away_name} className="team-logo" />
                <span className="team-name">{fixture.away_name}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FixturesList;