// src/utils/dynamodb.js

// Function to retrieve the standings data from the API
export const getStandings = async () => {
  try {
    const response = await fetch('https://yuvdyabwqh.execute-api.eu-west-1.amazonaws.com/PROD/standings');
    const data = await response.json();
    const standings = JSON.parse(data.body);

    // Convert the "played", "points", and "goalsDiff" fields to integers
    const convertedStandings = standings.map((team) => ({
      ...team,
      played: parseInt(team.played),
      points: parseInt(team.points),
      goalsDiff: parseInt(team.goalsDiff),
    }));

    return convertedStandings;
  } catch (error) {
    console.error('Error retrieving standings:', error);
    return [];
  }
};

// Function to retrieve the fixtures data from the API
export const getFixtures = async () => {
  try {
    const response = await fetch('https://yuvdyabwqh.execute-api.eu-west-1.amazonaws.com/PROD/fixtures');
    const data = await response.json();
    const fixtures = JSON.parse(data.body);

    // Convert the "home_score" and "away_score" fields to integers
    const convertedFixtures = fixtures.map((fixture) => ({
      ...fixture,
      home_score: fixture.home_score !== null ? parseInt(fixture.home_score) : null,
      away_score: fixture.away_score !== null ? parseInt(fixture.away_score) : null,
    }));

    return convertedFixtures;
  } catch (error) {
    console.error('Error retrieving fixtures:', error);
    return [];
  }
};
