// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import StandingsTable from './components/StandingsTable';
import FixturesList from './components/FixturesList';
import { getStandings, getFixtures } from './utils/dynamodb';
import './styles/App.css';
import logo from './assets/logo.png';
import Spinner from './components/Spinner';

const App = () => {
  const [standings, setStandings] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [scores, setScores] = useState({});
  const [showHelp, setShowHelp] = useState(false);
  const [loading, setLoading] = useState(true);
  const standingsRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const toggleHelp = () => {
    setShowHelp((prevState) => !prevState);
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    const standingsData = await getStandings();
    const fixturesData = await getFixtures();
    setStandings(standingsData);
    setFixtures(fixturesData);
    setLoading(false); // Set loading to false after data is fetched
  };

  const handleScoreUpdate = (updatedScores) => {
    setScores((prevScores) => {
      const newScores = { ...prevScores, ...updatedScores };
      return newScores;
    });
  };

  const handleShareStandings = (textToShare) => {
    navigator.clipboard.writeText(textToShare).then(
      () => {
        alert('Standings copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy standings: ', err);
      }
    );
  };

  // Calculate the number of permutations of the remaining fixtures by this formula: number of permutations = 3^(number of remaining fixtures)

  function formatLargeNumber(number) {
    if (number >= 1e27) {
      return (number / 1e27).toFixed(2) + ' × 10^27';
    } else if (number >= 1e24) {
      return (number / 1e24).toFixed(2) + ' × 10^24';
    } else if (number >= 1e21) {
      return (number / 1e21).toFixed(2) + ' × 10^21';
    } else if (number >= 1e18) {
      return (number / 1e18).toFixed(2) + ' × 10^18';
    } else if (number >= 1e15) {
      return (number / 1e15).toFixed(2) + ' × 10^15';
    } else if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + ' × 10^12';
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + ' billion';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + ' million';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + ' thousand';
    } else {
      return number.toString();
    }
  }

  const numPermutations = Math.pow(3, fixtures.length);

  const formattedPermutations = formatLargeNumber(numPermutations);

  const scrollToStandings = () => {
    if (window.innerWidth <= 768) {
      standingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} alt="Aguero.ooo" className="app-logo" />
        <div className="app-title">
          <h1 className="app-name">Aguero.ooo</h1>
          <p className="app-tagline">Predict the end of the Premier League.</p>
        </div>
      </header>
      <div>
        <button className="learn-more-button" onClick={toggleHelp}>
            Learn More.
        </button>
      </div>
      {showHelp && (
        <div className="app-description"><br />With this web app, you can predict the final standings of the <b>2023/24 Premier League</b> season and share your predictions with friends. There are two tables: <b>Fixtures</b> and <b>Standings</b>. Whenever you change anything in <b>Fixtures</b>, it updates the <b>Standings</b> table accordingly. <br /><br /> 
        There are some auto-populate buttons: <button>2-1</button> will fill in all unpopulated games with a score of 2-1 for the home team, and vice versa with the <button>1-2</button> button, while the <button>1-1</button> fills in a draw for the open matches. The <button>★</button> button fills the <b>Standings</b> table by letting the team with the highest current standing win. <span className='mobile-only'>Whenever you manually enter a score or click a button, scroll to the bottom to see the effect on the <b>Standings</b> table.</span><br /><br /> 
        You can manually enter scores, click the an auto-populate button, and manually alter scores again; it will update the <b>Standings</b> table accordingly. Once all games have been populated, you will see a <button>Share</button> button which will copy the first 4 and last 3 places to your clipboard. The <button>x</button> button clears the <b>Fixtures</b> table and resets the <b>Standings</b> table. <br /><br />
        <i>There are still {formattedPermutations} different outcomes left (3 ^ {fixtures.length}), and you decide how this will end!</i></div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <div className="app-content-wrapper">
          <div className="fixtures-container">
            <FixturesList
              fixtures={fixtures}
              standings={standings}
              onScoreUpdate={handleScoreUpdate}
              scores={scores}
              onAutoComplete={scrollToStandings}
            />
          </div>
          <div className="standings-container" ref={standingsRef}>
            <StandingsTable
              standings={standings}
              fixtures={fixtures}
              scores={scores}
              onShareStandings={handleShareStandings}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;