// src/components/StandingsTable.js
import React from 'react';
import { calculatePoints, calculateGoalDifference } from '../utils/calculations';
import '../styles/StandingsTable.css';

const StandingsTable = ({ standings, fixtures, scores, onShareStandings }) => {

  const updatedStandings = standings.map((team) => {
    let updatedTeam = { ...team };
    fixtures.forEach((fixture) => {
      const homeScore = scores[`${fixture.id}_home`] !== undefined ? parseInt(scores[`${fixture.id}_home`], 10) : fixture.home_score;
      const awayScore = scores[`${fixture.id}_away`] !== undefined ? parseInt(scores[`${fixture.id}_away`], 10) : fixture.away_score;
  
      if ((homeScore !== null && awayScore !== null) && (fixture.home_id === team.team_id || fixture.away_id === team.team_id)) {
        const [homePoints, awayPoints] = calculatePoints(homeScore, awayScore);
        const [homeGoalDiff, awayGoalDiff] = calculateGoalDifference(homeScore, awayScore);
  
        if (fixture.home_id === team.team_id) {
          updatedTeam.points += homePoints;
          updatedTeam.goalsDiff += homeGoalDiff;
        } else {
          updatedTeam.points += awayPoints;
          updatedTeam.goalsDiff += awayGoalDiff;
        }
        updatedTeam.played++;
      }
    });
    return updatedTeam;
  });

  const sortedStandings = [...updatedStandings].sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points;
    }
    return b.goalsDiff - a.goalsDiff;
  });

  const handleShare = () => {
    const today = new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' });
    const top4Teams = sortedStandings.slice(0, 4).map((team, index) => `${index + 1}. ${team.team_name}: ${team.points} (${team.goalsDiff})`).join('\n');
    const bottom3Teams = sortedStandings.slice(-3).map((team, index) => `${standings.length - 2 + index}. ${team.team_name}: ${team.points} (${team.goalsDiff})`).join('\n');
    const textToShare = `My 23/24 EPL Prediction on ${today}:\n\n${top4Teams}\n~\n${bottom3Teams}\n\nDo yours at:\nwww.aguero.ooo`;
    onShareStandings(textToShare);
  };

  return (
    <div className="standings-table">
      <div className="standings-header">
        <h2>Standings</h2>
        {fixtures.every((fixture) =>
          (scores[`${fixture.id}_home`] !== undefined && scores[`${fixture.id}_away`] !== undefined) ||
          (fixture.home_score !== null && fixture.away_score !== null)
        ) && (
          <button className="share-button" onClick={handleShare}>
            Share
          </button>
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Team</th>
            <th>Played</th>
            <th>Points</th>
            <th>Goal Diff.</th>
          </tr>
        </thead>
        <tbody>
          {sortedStandings.map((team, index) => (
            <tr key={team.team_id}>
              <td>{index + 1}</td>
              <td>
                <img src={team.team_logo} alt={team.team_name} className="team-logo" />
                <span className="team-name">{team.team_name}</span>
              </td>
              <td>{team.played}</td>
              <td>{team.points}</td>
              <td>{team.goalsDiff}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StandingsTable;